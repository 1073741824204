import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Image, Platform, Text, StyleSheet, View } from 'react-native';
import Constants from 'expo-constants';

/** App main. */
export default function App() {
    if (Platform.OS === 'web') {
        React.useEffect(() => {
            document.body.style.backgroundColor =
                Constants.manifest.splash.backgroundColor;
        }, []);
    }

    return (
        <View
            pointerEvents='none'
            style={[
                StyleSheet.absoluteFill,
                styles.container,
                {
                    backgroundColor: Constants.manifest.splash.backgroundColor,
                },
            ]}
        >
            <Image
                style={[
                    styles.image,
                    {
                        resizeMode:
                            Constants.manifest.splash.resizeMode || 'contain',
                    },
                ]}
                source={require('./assets/splash.png')}
            />
            <View style={styles.flex} />
            <Text style={styles.footer}>© 2021 Byterium Limited</Text>
            <StatusBar style='dark' />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flexGrow: 1,
    },
    image: {
        width: '80%',
        height: '80%',
    },
    flex: {
        flex: 1,
    },
    footer: {
        width: '100%',
        padding: 8,
        color: 'gray',
        textAlign: 'center',
        fontSize: 12,
    },
});
